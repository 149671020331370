import React from "react";
import Koulutus from "./koulutus";
import Section from "./baseComponents/section";
import Työnohjaus from "./tyonohjaus";
import Työyhteisösovittelu from "./tyoyhteisosovittelu";
import Työyhteisösovittelukoulutus from "./tyoyhteisosovittelukoulutus";

const Palvelut = () => {
  return (
    <Section title="Palvelut" anchor="palvelut">
      {/* työnohjaus */}
      <Työnohjaus />
      {/* työyhteisösovittelu */}
      <Työyhteisösovittelu />
      {/* työyhteisösovittelukoulutus */}
      <Työyhteisösovittelukoulutus />
      {/* koulutus */}
      <Koulutus />
    </Section>
  );
};
export default Palvelut;
