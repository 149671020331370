import React from "react";
import AnchorLink from "./anchorLink";
import walking from "../img/walking.svg";

const Hero = () => {
  return (
    <div className="pt-24">
      <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        {/* left */}
        <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
          <h1 className="my-4 text-5xl font-bold leading-tight">Ajanvirta</h1>
          <p className="leading-normal text-2xl mb-8">
            Työhyvinvointia, kehittämistä sekä yksilöiden tukea dialogisella
            otteella
          </p>
          <AnchorLink
            to="#yhteystiedot"
            text="Ota yhteyttä!"
            classes="mx-auto lg:mx-0 hover:underline z-10 bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
          />
        </div>
        {/* right */}
        <div className="w-full md:w-3/5 py-6 text-center">
          <img
            className="w-full md:w-4/5 z-10"
            src={walking}
            alt="walking in a forrest"
          />
        </div>
      </div>
    </div>
  );
};
export default Hero;
