import React from "react";
import Product from "./baseComponents/product";
import conference from "../img/conference.svg";

const Työyhteisösovittelukoulutus = () => {
  return (
    <Product
      title="Työyhteisösovittelu&shy;koulutus"
      imageSrc={conference}
      reverse
    >
      <p className="text-gray-800 mb-8">
        Työyhteisösovittelua on mahdollista tehdä myös organisaation sisäisesti,
        mikäli työyhteisössä on sovitteluun perehtyneitä vastuuhenkilöitä.
        Järjestämme työyhteisössä määritellyille, sovittelusta kiinnostuneille
        työyhteisön jäsenille koulutuksia, jotka antavat valmiudet toimia
        työyhteisön sovittelijana ja tehdä dialogista työyhteisösovittelua.
      </p>
      <p className="text-gray-800 mb-8">
        Työyhteisön sisäinen työyhteisösovittelumenettely sopii
        organisaatioille, jotka haluavat käyttää työyhteisösovittelua matalan
        kynnyksen puuttumisena työyhteisön ongelmiin. Koulutus räätälöidään
        yksilöllisesti työyhteisön tai organisaation tarpeisiin ja se sopii yhtä
        lailla isoille kuin pienillekin organisaatioille.
      </p>
    </Product>
  );
};

export default Työyhteisösovittelukoulutus;
