import React, { useState } from "react";
import menu from "../img/menu.svg";
import x from "../img/x.svg";
import classNames from "classnames";
import AnchorLink from "./anchorLink";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuClick = () => {
    setMenuOpen(!menuOpen);
  };
  const menuClassnames = classNames(
    "w-full flex-grow lg:flex lg:items-center lg:w-auto mt-2 z-20 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0",
    {
      hidden: !menuOpen,
      "transition-opacity ease-out duration-300": true,
    }
  );
  return (
    <nav
      id="header"
      className="fixed w-full z-30 top-0 text-white bg-white shadow"
    >
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
        <div className="pl-4 flex items-center">
          <AnchorLink
            to="#"
            text="Ajanvirta"
            classes="no-underline uppercase hover:no-underline font-bold text-2xl lg:text-4xl text-gray-800"
          />
        </div>
        <div className="block lg:hidden pr-4">
          <button
            id="nav-toggle"
            className="flex items-center p-1 text-pink-800 hover:text-gray-900 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out "
          >
            <img
              className="w-12 h-12"
              src={menuOpen ? x : menu}
              onClick={menuClick}
              alt="hamburger menu toggle button icon"
            />
          </button>
        </div>
        <div id="nav-content" className={menuClassnames}>
          <ul className="list-reset lg:flex justify-end flex-1 items-center">
            <li className="mr-3">
              <AnchorLink to="#palvelut" text="Palvelut" onClick={menuClick} />
            </li>
            <li className="mr-3">
              <AnchorLink to="#kuka" text="Helena" onClick={menuClick} />
            </li>
            <li className="mr-3">
              <AnchorLink
                to="#yhteystiedot"
                text="Yhteystiedot"
                onClick={menuClick}
              />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Header;
