import React from "react";
import Person from "./baseComponents/person";
import helena from "../img/helena.jpg";

const Helena = () => {
  return (
    <Person
      name="Helena Pohjanvirta"
      shortDesc="Psykoterapeutti, työnohjaaja, NLP Master Practicioner, NLP Master Coach."
      imgSrc={helena}
    >
      <p className="pt-8 text-sm">
        Olen tehnyt työnohjauksia ja työyhteisön kehittämistä vuodesta 2000.
        Erityisosaamistani on konfliktien sovittelu. Olen kouluttanut
        työyhteisösovittelijoita julkisyhteisöille ja työyhteisösovittelua
        täydennyskoulutuksena ammattilaisille. Käytän dialogisia, ratkaisu- ja
        voimavarakeskeisiä työskentelymenetelmiä. Olen myös perehtynyt
        psykofyysisen terapiaan ja kriisityöskentelyyn.
      </p>
      <p className="pt-2 text-sm">
        Minulla on 25 vuotta työkokemusta rikos- ja riita-asioiden sovittelun
        vastuuhenkilönä. Tällä hetkellä työskentelen Mikkelin kriisikeskuksen
        johtajana.
      </p>
    </Person>
  );
};
export default Helena;
