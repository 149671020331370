import React from "react";
import Helena from "./helena";
import Section from "./baseComponents/section";
const Me = () => {
  return (
    <Section title="Kuka" anchor="kuka">
      <Helena />
    </Section>
  );
};

export default Me;
