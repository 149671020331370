import React from "react";

interface personProps {
  imgSrc: string;
  name: string;
  shortDesc: string;
  children: React.ReactNode;
}
const Person = (props: personProps) => {
  const { imgSrc, name, shortDesc, children } = props;

  const styles = {
    backgroundImage: `url(${imgSrc})`,
  };
  return (
    <div className="max-w-4xl flex items-center h-auto flex-wrap mx-auto my-12 lg:my-0">
      <div
        id={`profile-${name}`}
        className="w-full lg:w-3/5 rounded-lg lg:rounded-l-lg lg:rounded-r-none shadow-2xl bg-white opacity-75 mx-6 lg:mx-0"
      >
        <div className="p-4 md:p-12 text-center lg:text-left">
          <div
            className="block lg:hidden rounded-full shadow-xl mx-auto -mt-16 h-48 w-48 bg-cover bg-center"
            style={styles}
          ></div>
          <h3 className="text-3xl font-bold pt-8 lg:pt-0">{name}</h3>
          <div className="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-green-500 opacity-25"></div>
          <p className="pt-4 text-base font-bold flex items-center justify-center lg:justify-start">
            {shortDesc}
          </p>
          {children}
        </div>
      </div>
      <div className="w-full lg:w-2/5">
        <img
          className="rounded-none lg:rounded-lg shadow-2xl hidden lg:block"
          src={imgSrc}
          alt="person portrait"
        />
      </div>
    </div>
  );
};
export default Person;
