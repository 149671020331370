import React from "react";
import Product from "./baseComponents/product";
import conversation from "../img/conversation.svg";

const Työnohjaus = () => {
  return (
    <Product
      title="Työnohjaus ja yhteisökehittäminen"
      imageSrc={conversation}
      reverse
    >
      <p className="text-gray-800 mb-8">
        Työnohjaus on koko työyhteisön tai yksittäisen työntekijän työskentelyä
        koulutetun työnohjaajan kanssa. Työnohjauksen kautta kehitetään
        yhteisöllisyyttä ja kirkastetaan ryhmän ja yksilöiden tavoitteita ja
        rooleja. Dialogisen prosessin myötä työyhteisö tunnistaa omat
        voimavaransa ja ryhmälle luodaan välineet ratkaisujen löytämiseen.
      </p>
      <p className="text-gray-800 mb-8">
        Työnohjausprosessissa määritellään yksilön tai yhteisön kulloinenkin
        tavoite. Työskentelyn keskiössä voi olla esimerkiksi uusien näkökulmien
        etsiminen, osallistujien voimavarojen lisääminen tai yhteisön yhteistyön
        paraneminen. Muita tavoitteita voivat olla työn imun ja työilon
        lisääminen ja ylläpitäminen tai osallistujien ammatti-identiteetin
        selkiyttäminen. Parhaimillaan työnohjaus on jatkuva oppimisprosessi.
      </p>
      <p className="text-gray-800 mb-8">
        Työyhteisöä voi kehittää myös muista näkökulmista kuin työnohjauksen
        kautta. Laajemmassa yhteisön kehittämisprosessissa työtavat rakennetaan
        kulloisenkin tarpeen mukaan. Lopputulos pyrkii sujuvaan työhön,
        toimivaan työyhteisöön ja selkeisiin tehtäväkokonaisuuksiin.
        Yhteisökehittäminen soveltuu esimerkiksi työprosessien selkeyttämiseen
        tai kun työyhteisöissä on jännitteitä.
      </p>
    </Product>
  );
};

export default Työnohjaus;
