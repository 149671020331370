import React from "react";
import { HashLink } from "react-router-hash-link";

interface anchorLinkProps {
  to: string;
  text: string;
  classes?: string;
  onClick?: () => void;
}

const AnchorLink = (props: anchorLinkProps) => {
  const { to, text, classes, onClick } = props;
  return (
    <HashLink
      smooth
      to={to}
      className={
        classes
          ? classes
          : "inline-block py-2 px-4 text-black font-bold no-underline"
      }
      onClick={onClick}
    >
      {text}
    </HashLink>
  );
};

export default AnchorLink;
