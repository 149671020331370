import React from "react";

import teaching from "../img/teaching.svg";
import Product from "./baseComponents/product";

const Koulutus = () => {
  return (
    <Product title="Muut koulutukset" imageSrc={teaching}>
      <p className="text-gray-800 mb-8">
        Räätälöimme yhteisön kehittämiseen ja ammatilliseen kehittymiseen
        liittyviä koulutuksia erilaisiin tarpeisiin. Koulutukset soveltuvat
        erinomaisesti esimiestyössä, asiakaspalvelussa, kouluttamisessa ja
        ohjaamisessa toimiville ammattilaisille sekä kaikille, jotka haluavat
        päästä parempaan vuorovaikutukseen toisten kanssa.
        <br />
        Järjestämme mm. seuraavanlaisia, yksilöllisesti räätälöityjä
        koulutuksia:
      </p>
      <ul className="list-disc list-inside ">
        <li className="text-gray-800">
          dialogiset ja voimavarakeskeiset asiakastyön koulutukset erilaisiin
          tarpeisiin
        </li>
        <li className="text-gray-800">
          sovittelijakoulutukset lisäämään konfliktien kohtaamiseen tarvittavia
          taitoja mm. opetus, kasvatus- ja sosiaalialan henkilöstölle
        </li>
        <li className="text-gray-800">
          esimieskoulutukset konfliktitilanteiden hallintaan
        </li>
        <li>dialogiset vuorovaikutuskoulutukset</li>
        <li className="text-gray-800">
          työyhteisöjen vuorovaikutukseen ja puhekäytäntöjen kehittämiseen
          liittyvät koulutukset
        </li>
        <li className="text-gray-800">
          puheeksiottokoulutukset esimerkiksi esimiehille haasteellisten
          asioiden käsittelyyn
        </li>
      </ul>
      <p className="text-gray-800 mb-8 mt-8">
        Dialogisissa koulutuksissa keskitytään vuorovaikutustaitoihin: kuinka
        kuunnella niin, että toisen tarkoittamat merkitykset tulevat kuulluksi
        ja ymmärretyksi, ja kuinka toisaalta houkutella vastapuolta
        kuuntelemaan. Koulutusten tarkoitus on antaa työkalut tulla kuulluksi ja
        ymmärretyksi sekä ymmärtää ja kuulla muita. Dialogisten
        vuorovaikutustaitojen avulla keskustelija voi vaikuttaa siihen,
        millaista vuorovaikutus on. Dialogisen keskustelijan on helpompi päästä
        vaikuttamaan työskentelytapoihin ja tuomaan uusia tapoja työyhteisöön.
      </p>
    </Product>
  );
};
export default Koulutus;
