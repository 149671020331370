import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Switch } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <App />
        </Route>
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// <StateMachineProvider>
//   {process.env.NODE_ENV !== "production" && <DevTool />}
// </StateMachineProvider>,

// import { createStore, StateMachineProvider } from "little-state-machine";
// import { DevTool } from "little-state-machine-devtools";
// createStore({
//   counter: 1,
// });
