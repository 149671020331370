import React from "react";
import Product from "./baseComponents/product";
import shared_workspace from "../img/shared_workspace.svg";

const Työyhteisösovittelu = () => {
  return (
    <Product title="Työyhteisösovittelu" imageSrc={shared_workspace}>
      <p className="text-gray-800 mb-8">
        Työyhteisösovittelussa pyritään ratkaisemaan työyhteisön ristiriitoja.
        Se sopii yhteisöille, joilla on selvittämättömmiä konflikteja tai
        erimielisyyksiä. Vaikka ristiriidat kuuluvat työelämään ja
        parhaimmillaan auttavat kehittämisessä ja uuden luomisessa, ne
        muodostuvat pulmallisiksi, jos ne vievät huomion perustehtävän
        hoitamisesta. Ristiriitainen työyhteisö vähentää työhyvinvointia ja
        lisää työstä poissaoloja.
      </p>
      <p className="text-gray-800 mb-8">
        Työyhteisösovittelussa autetaan löytämään tavoitteita ja ratkomaan
        työyhteisöissä syntyneitä ristiriitatilanteita. Työyhteisösovittelu on
        parhaimmillaan oppimisprosessi, jossa opitaan itsestä ja toisista.
      </p>
      <p className="text-gray-800 mb-8">
        Sovittelu voi olla kahdenkeskistä, useamman henkilön välistä tai koko
        työyhteisöä koskevaa. Sovitteluprosessi etenee siten, että sovittelija
        kuuntelee ensin kaikkia osapuolia erikseen. Sen jälkeen yhteisessä
        kohtaamisessa etsitään tilanteisiin ratkaisuja yhdessä.
      </p>
    </Product>
  );
};

export default Työyhteisösovittelu;
