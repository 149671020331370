import React from "react";
const Footer = () => {
  return (
    <footer id="yhteystiedot" className="bg-white">
      <div className="container mx-auto my-auto px-8 py-16 text-center">
        <div className="w-full flex flex-col md:flex-row py-6">
          <div className="flex-1">
            <h5 className="uppercase text-gray-500 md:mb-6">Ajanvirta</h5>
            <p className="my-1">Y-tunnus: 1727306-4</p>
            <p className="my-1">
              Kuumavuorenkatu 12 <br />
              50130 Mikkeli
            </p>
          </div>
          <div className="flex-1">
            <h5 className="uppercase text-gray-500 md:mb-6">Yhteystiedot</h5>
            <p className="my-1">puh. 040-0430204</p>
            <p className="my-1">email. info@ajanvirta.fi</p>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
