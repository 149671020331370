import React from "react";
import Header from "./components/header";
import Hero from "./components/hero";
import WaveToWhite from "./components/waveToWhite";
import WaveFromWhite from "./components/waveFromWhite";
import Footer from "./components/footer";
import Palvelut from "./components/palvelut";
import Me from "./components/me";

function App() {
  return (
    <div className="from-green-500 to-blue-500 bg-gradient-to-r">
      {/* header */}
      <Header />
      {/* Hero */}
      <Hero />
      {/* Wave */}
      <WaveToWhite />
      {/* Sections */}
      <Palvelut />
      {/* tekijät */}
      <Me />
      {/* wave */}
      <WaveFromWhite />
      <section className="container mx-auto text-center py-6 mb-12">
        <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-white">
          Ajanvirta
        </h2>
        <div className="w-full mb-4">
          <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
        </div>
        <h3 className="my-4 text-3xl leading-tight">
          Työhyvinvointia, kehittämistä sekä yksilöiden tukea dialogisella
          otteella
        </h3>
      </section>
      <Footer />
    </div>
  );
}

export default App;
