import classNames from "classnames";
import React from "react";
import WaveFromWhite from "../waveFromWhite";
import WaveToWhite from "../waveToWhite";

interface productParams {
  title: string;
  imageSrc: string;
  reverse?: boolean;
  children: React.ReactNode;
}
const Product = (props: productParams) => {
  const { title, imageSrc, reverse } = props;
  const flexClasses = classNames("flex flex-wrap", {
    "flex-row-reverse": reverse,
    "justify-end": reverse,
  });
  return (
    <div className={flexClasses}>
      <div className="w-full sm:w-1/2 p-6">
        <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
          {title}
        </h3>
        {props.children}
      </div>
      <div className="w-full sm:w-1/2 p-6">
        <img
          className="w-full sm:h-64 mx-auto"
          src={imageSrc}
          alt={`product ${title} illustration`}
        />
      </div>
    </div>
  );
};

export default Product;
