import React from "react";
interface sectionProps {
  title: string;
  anchor: string;
  children: React.ReactNode;
}
const Section = (props: sectionProps) => {
  const { title, anchor, children } = props;
  return (
    <section id={anchor} className="bg-white py-8">
      <div className="container max-w-5x1 mx-auto m-8">
        <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
          {title}
        </h2>
        <div className="w-full mb-4">
          <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
        </div>
        {children}
      </div>
    </section>
  );
};
export default Section;
